$(document).ready(function() {



  function removeLoader(){
      $( "#loading" ).fadeOut(1000, function() {
        $( "#loading-image" ).remove();
    });  
  }
  function loadSite() {
    removeLoader();
    $( "#loading-image" ).remove();
  }
  loadSite();


  document.ontouchmove = function(event){
    event.preventDefault();
}

  // your code

// slick slider start
  $('.banner__wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });

  // slick slider end

  $('.modal__slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false
      }
    }]
  });

  
$(window).scroll(function() {
  if ($(document).scrollTop() < 200) {
    $(".nav__logo").removeClass("active");
    $(".nav_img").removeClass("active");
    $("nav").removeClass("active");
  } else {
    $(".nav__logo").addClass("active");
    $(".nav_img").addClass("active");
    $("nav").addClass("active");
  }
});
if ($(window).width() < 576) {
  if ($(document).scrollTop() == 0) {
    $(".nav__logo").removeClass("active");
    $(".nav_img").removeClass("active");
    $("nav").removeClass("active");
  } else {
    $(".nav__logo").addClass("active");
    $(".nav_img").addClass("active");
    $("nav").addClass("active");
  }
}

})


$(".btn__price__list").click(function() {
  $(this).toggleClass("active");
  $(".black__layar").toggleClass("active");
  $(".price__img__wrapper").toggleClass("active");
})


$(".mobile-menu").click(function() {
  $(".mobile-menu").toggleClass("active");
  $("body").toggleClass("active");
  $(".sidenav-list-wrapper").toggleClass("active");
})

$(".cross__btn").click(function() {
  $(".modal-wrapper").removeClass("active");
  $(".pant__house__modal-wrapper").removeClass("active");
  $(".black__layar").removeClass("active");
  $("body").removeClass("active__fixed");
})



$(".close__modal").click(function() {
  $(".black__layar").removeClass("active");
  $(".residance__modal__wrapper-0").removeClass("active");
  $(".residance__modal__wrapper-1").removeClass("active");
  $(".residance__modal__wrapper-2").removeClass("active");
  $(".residance__modal__wrapper-3").removeClass("active");
  $(".residance__modal__wrapper-4").removeClass("active");
  $(".residance__modal__wrapper-5").removeClass("active");
  $(".residance__modal__wrapper-6").removeClass("active");
  $(".residance__modal__wrapper-7").removeClass("active");
  $(".residance__modal__wrapper-8").removeClass("active");
})


$(".btn0").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-0").addClass("active");
  $('.residance__modal__wrapper-0 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn1").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-1").addClass("active");
  $('.residance__modal__wrapper-1 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn2").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-2").addClass("active");
  $('.residance__modal__wrapper-2 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn3").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-3").addClass("active");
  $('.residance__modal__wrapper-3 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn4").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-4").addClass("active");
  $('.residance__modal__wrapper-4 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn5").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-5").addClass("active");
  $('.residance__modal__wrapper-5 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn6").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-6").addClass("active");
  $('.residance__modal__wrapper-6 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn7").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-7").addClass("active");
  $('.residance__modal__wrapper-7 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})

$(".btn8").click(function() {
  $(".black__layar").addClass("active");
  $(".residance__modal__wrapper-8").addClass("active");
  $('.residance__modal__wrapper-8 .residence__modal__img__content-wrapper .wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
    easing: 'easeOutElastic',
  });
})
// ESCAPE key pressed function
jQuery(document).keydown(function (e) {
  if (e.keyCode == 27) {
    $(".modal-wrapper").removeClass("active");
    $(".black__layar").removeClass("active");
    $("body").removeClass("active__fixed");
  }
})

$(".btn").click(function () {
  var slide_data = $(this).attr("data-index")
  if (slide_data === slide_data) {
    $.getJSON("../modals.json", function (data) {
      console.log("something")
      for (p = 0; p < 8; p++) {
        if (p == slide_data) {
          jQuery(".modal-wrapper").addClass("active")
         
          var c0 = $(".modals0").attr("data-index")
          var c1 = $(".modals1").attr("data-index")
          var c2 = $(".modals2").attr("data-index")
          var c3 = $(".modals3").attr("data-index")
          var c4 = $(".modals4").attr("data-index")
          var c5 = $(".modals5").attr("data-index")
          var c6 = $(".modals6").attr("data-index")
          var c7 = $(".modals7").attr("data-index")
          if (p == c0) {
            jQuery(".modals0").removeClass("d-none")
            jQuery(".modals0").siblings().addClass("d-none")
            
          }
          if (p == c1) {
            jQuery(".modals1").siblings().addClass("d-none")
            jQuery(".modals1").removeClass("d-none")
          }
          if (p == c2) {
            jQuery(".modals2").siblings().addClass("d-none")
            jQuery(".modals2").removeClass("d-none")
          }
          if (p == c3) {
            jQuery(".modals3").siblings().addClass("d-none")
            jQuery(".modals3").removeClass("d-none")
          }
          if (p == c4) {
            jQuery(".modals4").siblings().addClass("d-none")
            jQuery(".modals4").removeClass("d-none")
          }
          if (p == c5) {
            jQuery(".modals5").siblings().addClass("d-none")
            jQuery(".modals5").removeClass("d-none")
          }
          if (p == c6) {
            jQuery(".modals6").siblings().addClass("d-none")
            jQuery(".modals6").removeClass("d-none")
          }
          if (p == c7) {
            jQuery(".modals7").siblings().addClass("d-none")
            jQuery(".modals7").removeClass("d-none")
          }
        }
      }
    })
  }
})



// slide 1
var slideIndex = 1;
showSlides(slideIndex);

function plusSlides(n) {
  showSlides(slideIndex += n);
}

function currentSlide(n) {
  showSlides(slideIndex = n);
}

function showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("mySlides");
  var dots = document.getElementsByClassName("demo");
  if (n > slides.length) {slideIndex = 1}
  if (n < 1) {slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[slideIndex-1].style.display = "flex";
  dots[slideIndex-1].className += " active";
}
// -------------

// slide 2

function myFunction(imgs) {
  var expandImg = document.getElementById("expandedImg");
  expandImg.src = imgs.src;
  expandImg.parentElement.style.display = "block";
}

function myFunction2(imgs) {
  var expandImages = document.getElementById("expandedImages");
  expandImages.src = imgs.src;
  expandImages.parentElement.style.display = "flex";
}


// -------------





